// main SCSS file

/*! Import variables */
@import "../../node_modules/startbootstrap-freelancer/src/scss/_variables.scss";
@import "variables";

/*! Import Bootstrap styles */
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

/*! Import startbootstrap-freelancer (styles.scss content) */
@import "../../node_modules/startbootstrap-freelancer/src/scss/_global.scss";
@import "../../node_modules/startbootstrap-freelancer/src/scss/components/_divider.scss";
@import "../../node_modules/startbootstrap-freelancer/src/scss/components/_buttons.scss";
@import "../../node_modules/startbootstrap-freelancer/src/scss/components/_navbar.scss";
@import "../../node_modules/startbootstrap-freelancer/src/scss/components/_forms.scss";
@import "../../node_modules/startbootstrap-freelancer/src/scss/sections/_masthead.scss";
@import "../../node_modules/startbootstrap-freelancer/src/scss/sections/_portfolio.scss";
@import "../../node_modules/startbootstrap-freelancer/src/scss/sections/_footer.scss";

/*! Import fonts */
// @import "../../vendor/fontsource/montserrat/400.css";
// @import "../../vendor/fontsource/montserrat/700.css";

/*! Import fontawesome icons */
$fa-font-path: "../../vendor/fontawesome/webfonts";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
// @import "../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

/*! Import customized styles */
@import "custom";

/*! Import vendors */

// satisfy vscode intellisense
.js-scroll-trigger {
    min-width: 0;
}