// variables

$colorMain: #ff8800;
$colorSub: #04519F;
$tophead-bg: #FFFFFF;
$tophead-height: 80px;
$tophead-height-sm: 50px;
$main-header-height: 450px;
$main-header-height-sm: 300px;

//
// startbootstrap _variables.scss
//
// For adjusting the scroll target position of internal anchor when having a fixed navbar on top.
// this is more a measured value as the height is defined by paddings.
$navbar-height: 136px;
$navbar-height-sm: 108px;

//
// startbootstrap _variables.scss and/or Bootstrap
//
$enable-smooth-scroll: true;

// Bootstrap grid rows 
$grid-row-columns: 8;

// Bootstrap utilities
$spacer: 1rem;
$spacers: (0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    // more
    7: $spacer * 7,
    8: $spacer * 8,
    9: $spacer * 9,
    // negative
    n1: $spacer * -0.25,
    n2: $spacer * -0.5,
    n3: $spacer,
    n4: $spacer * -1.5,
    n5: $spacer * -3,
);

// Bootstrap colors
$primary: $colorMain;
$secondary: $colorSub;
$custom-colors: ("gray-100": #f8f9fa,
    "gray-200": #e9ecef,
    "gray-300": #dee2e6,
    "gray-400": #ced4da,
    "gray-500": #adb5bd,
    "gray-600": #6c757d,
    "gray-700": #495057,
    "gray-800": #343a40,
    "gray-900": #212529,
    "darkest": #0e1010,
    "black": #000000);

// Bootstrap navbar
$navbar-padding-y: 0;

// Bootstrap radius
$border-radius: 0.5rem;