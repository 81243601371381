// CUSTOM

// for correct scoll padding (overwrite startbootstrap)
html {
    @include media-breakpoint-down(lg) {
        scroll-padding-top: calc(#{$navbar-height-sm} - 1px);
    }
}

// for custom tophead (on top/before of the navbar)
#tophead {
    background-color: $tophead-bg;

    img {
        height: $tophead-height;

        @include media-breakpoint-down(lg) {
            height: $tophead-height-sm;
        }
    }

    .tophead-text {
        @include font-size(1.5rem);

        @include media-breakpoint-down(lg) {
            @include font-size(0.9rem);
        }
    }
}

// for navbar (overwrite bootstrap & startbootstrap)
#mainNav {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .navbar-brand {
        font-weight: 100;
        letter-spacing: .35em;
        @include font-size(1.25rem);

        @include media-breakpoint-down(lg) {
            letter-spacing: 0.04em;
            @include font-size(1rem);
        }
    }
}

@include media-breakpoint-up(lg) {
    #mainNav {
        .navbar-brand {
            font-size: 1.25em;
        }
    }
}

// for mainHeader (custom)
#mainHeader {
    height: $main-header-height;

    @include media-breakpoint-down(lg) {
        height: $main-header-height-sm;
    }
}

// for word-wrap
h1,
h2,
h3,
p {
    // mind <html lang="de">!
    overflow-wrap: break-word;
    word-wrap: break-word;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.wrap-between-words {
    // not working - a try:
    white-space: break-spaces !important;
    word-break: unset !important;
    word-wrap: unset !important;
    overflow-wrap: unset !important;
    -webkit-hyphens: unset !important;
    -moz-hyphens: unset !important;
    -ms-hyphens: unset !important;
    hyphens: unset !important;
}

// for background gradients
.fwg-bg-gradient {
    @include gradient-x($primary, rgb(255, 225, 0), 0%, 100%);
}

// for images
.img-filter-dark {
    filter: brightness(50%);
}

.img-filter-blur {
    filter: blur(4px);
}

.img-filter-dark-blur {
    filter: blur(4px) brightness(50%);
}

// for accordion
.accordion {
    // overwride Bootstrap CSS variables
    --bs-accordion-active-bg: #{$colorMain};
    --bs-accordion-btn-color: #{$colorSub};
    --bs-accordion-active-color: #{$colorSub};
    --bs-accordion-border-color: #{mix(white, $primary, 60%)};

    .accordion-button:not(.collapsed) {
        @extend .fwg-bg-gradient;
    }
}